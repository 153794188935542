/*  ! README !
    
    - EVERY class name has to consist of:
    - "bg-" prefix
    - name of the page that its used in - identical with the page's src file name
    - section whenre it's used withing the page (eg.: "intro", "grid", "faq" etc.)
    - EVERY class has to extend the defaults unless a specific scenario dictates otherwise
    - group of classes for the same page need to be separated by  === {PAGE} === comment
    - if the image has separate version for desktop and mobile, you must define the desktop one via media query
*/

.defaults {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

/* ======================================== Coaching page ======================================== */
.bg-coaching-intro {
  @extend .defaults;
  background-image: url('../assets/images/coaching_intro_bg_mobile.svg');
}
@media (min-width: 1024px) {
  .bg-coaching-intro {
    background-image: url('../assets/images/coaching_intro_bg.svg');
  }
}

/* ======================================== CoursesPage ======================================== */
.bg-courses-intro {
  @extend .defaults;
  background-image: url('../assets/images/courses_intro_mobile_bg.svg');
}
@media (min-width: 1024px) {
  .bg-courses-intro {
    background-image: url('../assets/images/courses_intro_bg.svg');
  }
}

.bg-courses-subscriptions {
  @extend .defaults;
  background-position: initial;
  background-image: url('../assets/images/courses_subscription_bg_mobile.svg');
}
@media (min-width: 1024px) {
  .bg-courses-subscriptions {
    background-image: url('../assets/images/courses_subscription_bg.svg');
  }
}

/* ======================================== CourseDetailPage ======================================== */
.bg-course-detail-intro {
  @extend .defaults;
  background-size: cover;
  background-position: bottom;
  background-image: url('../assets/images/single-course-intro-mobile.svg');
}
@media (min-width: 1024px) {
  .bg-courses-subscriptions {
    background-image: url('../assets/images/single-course-page-intro.svg');
  }
}
.bg-course-detail-curricullumm {
  @extend .defaults;
  background-position: top;
  background-image: url('../assets/images/single-course-page-curricullum.svg');
}
.bg-course-detail-bottom {
  @extend .defaults;
  background-position: bottom;
  background-size: cover;
  background-image: url('../assets/images/single-course-page-bottom.svg');
}

/* ======================================== FAQ Page ======================================== */

.bg-faq {
  background-repeat: no-repeat;
  background-position: top, bottom;
  background-size: 100% auto;
  background-image: url('../assets/images/faq-teal-wave-mobile.png'),
    url('../assets/images/faq-purple-wave-mobile.png');
}
@media (min-width: 768px) {
  .bg-faq {
    background-image: url('../assets/images/faq-teal-wave.png'), url('../assets/images/faq-purple-wave.png');
  }
}

/* ======================================== IATPage ======================================== */
.bg-iat-intro {
  @extend .defaults;
  background-image: url('../assets/images/iat_intro_bg.svg');
}

/* ======================================== LifetimePage ======================================== */
.bg-lifetime-intro {
  @extend .defaults;
  background-image: url('../assets/images/lifetime-banner-bg-mobile.svg');
}

@media only screen and (min-width: 1024px) {
  .bg-lifetime-intro {
    background-image: url('../assets/images/lifetime-banner-bg.svg');
  }
}

/* ======================================== MembershipsPage ======================================== */
.bg-memberships-intro {
  @extend .defaults;
  background-image: url('../assets/images/memberships_intro_bg_mobile.svg');
}
@media only screen and (min-width: 1024px) {
  .bg-memberships-intro {
    background-image: url('../assets/images/memberships_intro_bg.svg');
  }
}

.bg-memberships-courses {
  @extend .defaults;
  background-image: url('../assets/images/memberships_courses_bg.svg');
  background-position: top;
}

/* ======================================== SocialEventsPage ======================================== */
.bg-social-events {
  @extend .defaults;
  background-image: url('../assets/images/socials-bg-wave-mobile.svg');
}
@media only screen and (min-width: 768px) {
  .bg-social-events {
    background-image: url('../assets/images/socials-bg-wave.svg');
  }
}

/* ======================================== QuizPage ======================================== */
@media only screen and (min-width: 768px) {
  .bg-quiz-intro {
    background-image: url('../assets/images/quiz-intro-bg.svg') !important;
  }
}

.bg-quiz-intro {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url('../assets/images/quiz-intro-mobile-bg.svg');
}

/* ======================================== QuizResultsPage ======================================== */
@media only screen and (min-width: 1024px) {
  .bg-quiz-results-intro-family {
    background-image: url('../assets/images/quiz-results-family-intro-bg.svg') !important;
  }
  .bg-quiz-results-intro-friends {
    background-image: url('../assets/images/quiz-results-friends-intro-bg.svg') !important;
  }
  .bg-quiz-results-intro-romantic {
    background-image: url('../assets/images/quiz-results-romantic-intro-bg.svg') !important;
  }
}

.bg-quiz-results-intro-family {
  @extend .defaults;
  background-image: url('../assets/images/quiz-results-family-intro-bg-mobile.svg');
}
.bg-quiz-results-intro-friends {
  @extend .defaults;
  background-image: url('../assets/images/quiz-results-friends-intro-bg-mobile.svg');
}
.bg-quiz-results-intro-romantic {
  @extend .defaults;
  background-image: url('../assets/images/quiz-results-romantic-intro-bg-mobile.svg');
}

@media only screen and (min-width: 1024px) {
  .bg-quiz-other-quizzes {
    background-image: url('../assets/images/quiz-results-footer-desktop-bg.svg');
  }
}
.bg-quiz-other-quizzes {
  background-image: url('../assets/images/quiz-results-footer-mobile-bg.svg');
  background-repeat: no-repeat;
  //   background-position: bottom;
  background-size: cover;
}
