html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Ensures "scrollIntoView()" methods are smooth not "jumpy" */
  scroll-behavior: smooth;

  /* height: 100vh;
  overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* FROM OLD FILES
    #root {
        height: 100%;
        display: flex;

        color: $white;
    } 
*/

#root {
  display: flex;
  /* flex-direction: column; */
  position: relative;
  font-family: 'Effra' !important;
}

/* External library - Carousel */
.carousel.carousel-slider {
  padding-bottom: 80px;
}
/* External library - Carousel */
.control-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-player-right-scroll {
  height: calc(100% - 125px);
}

/* External library - Swiper */
.swiper-wrapper {
  align-items: center;
}

.dialog {
  width: 100%;
  max-width: 420px;
  max-height: 100vh;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ======================================== */

/*                   FONTS                  */

/* ======================================== */
@font-face {
  font-family: 'Effra';
  src: local('Effra'), url(./assets/fonts/Effra-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'EffraBold';
  src: local('EffraBold'), url(./assets/fonts/Effra-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSerifPro';
  src: local('SourceSerifPro'), url(./assets/fonts/SourceSerifPro-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'SourceSerifPro-Bold';
  src: local('SourceSerifPro-Bold'), url(./assets/fonts/SourceSerifPro-Bold.ttf) format('truetype');
}

/* ======================================== */

/*              TAILWIND CSS                */

/* ======================================== */

/* MEDIUM PHONES */
@media only screen and (min-width: 375px) {
  .paddings {
    /* @apply px-page-xxs; */

    padding-left: 1.625rem /* 26px */;
    padding-right: 1.625rem /* 26px */;
  }
}

/* TABLETS */
@media only screen and (min-width: 768px) {
  .paddings {
    /* @apply px-20; */
    padding-left: 5rem !important /* 80px */;
    padding-right: 5rem !important /* 80px */;
  }

  .email-subscription-icon svg {
    width: 32px !important;
    height: 32px !important;
  }
}

/* SMALL LAPTOPS */
@media only screen and (min-width: 1024px) {
  /* .dialog {
    max-width: 1410px !important;
  } */

  .footer-content {
    border-bottom: solid 1px #5d5d5d;
  }
  .footer-content svg {
    width: 100px;
    height: 100px;
  }
}

/* MEDIUM LAPTOPS / SMALL DESKTOP */
@media only screen and (min-width: 1280px) {
  .paddings {
    /* @apply px-page-md; */

    padding-left: 6.25rem !important /* 100px */;
    padding-right: 6.25rem !important /* 100px */;
  }

  .email-subscription-icon svg {
    width: 56px !important;
    height: 56px !important;
  }
}

/* MEDIUM DESKTOP */
@media only screen and (min-width: 1440px) {
}

/* DESKTOP */
@media only screen and (min-width: 1536px) {
  .paddings {
    /* @apply px-page; */

    padding-left: 13.75rem !important /* 220px */;
    padding-right: 13.75rem !important /* 220px */;
  }
}

/* LARGE DESKTOP */
@media only screen and (min-width: 1920px) {
  /* had to make this workaround due to TW being buggy */
  .footer {
    padding-left: 17.5rem !important;
    padding-right: 17.5rem !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

@layer components {
  /* ======================================== */
  /*                   D E V                  */
  /* ======================================== */
  .dev-border-red {
    @apply border-2 border-red-500;
  }
  .dev-border-green {
    @apply border-2 border-green-500;
  }
  .dev-border-purple {
    @apply border-2 border-purple-700;
  }
  /* ======================================== */
  /*                 F O N T S                */
  /* ======================================== */
  .font-effra {
    font-family: 'Effra' !important;
  }
  .font-effra-bold {
    font-family: 'EffraBold' !important;
  }
  .font-ssp {
    font-family: 'SourceSerifPro' !important;
  }
  .font-sspb {
    font-family: 'SourceSerifPro-Bold' !important;
  }

  /* ======================================== */
  /*                C O M M O N               */
  /* ======================================== */

  .paddings {
    @apply px-3;
    /* xxs:px-page-xxs 
        md:px-20 
        xl:px-page-md 
        2xl:px-page; */
  }

  .default-padding {
    @apply p-2 xxs:p-3 xs:p-4;
  }

  .page {
    @apply w-full h-full flex flex-col relative;
  }

  .clickable {
    @apply cursor-pointer transform transition ease-in-out
            lg:hover:scale-105; /* lg:hover:shadow-lg; */
  }
  .clickable-no-pointer {
    @apply transform transition ease-in-out
        lg:hover:scale-105;
  }
  .clickable-no-scale {
    @apply cursor-pointer transform transition ease-in-out;
  }
  .clickable-shadow {
    @apply cursor-pointer transform transition ease-in-out
            lg:hover:scale-105 lg:hover:shadow-lg;
  }

  .bg-mobile {
    @apply w-full absolute -z-1
            lg:hidden;
  }
  .bg-desktop {
    @apply hidden w-full absolute -z-1
            lg:block;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
  .row {
    @apply flex flex-row;
  }
  .col {
    @apply flex flex-col;
  }

  .reviewCard {
    @apply h-auto flex flex-col text-left border rounded-2xl border-primary-light bg-white p-6;

    min-height: 355px;
    max-width: 18.75rem;
    padding-top: 3.25rem;
  }

  .select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  .mdStyles a {
    @apply text-primary;
  }

  .icon-gray {
    filter: invert(59%) sepia(13%) saturate(215%) hue-rotate(193deg) brightness(90%) contrast(90%);
  }
  .icon-primary {
    filter: invert(37%) sepia(14%) saturate(2188%) hue-rotate(239deg) brightness(96%) contrast(85%);
  }

  input {
    @apply focus:!ring-0;
  }

  [type='checkbox'] {
    @apply focus:!ring-0 focus:!ring-offset-0;
  }

  /* ======================================== */

  /*               OLD PROJECT                */

  /* ======================================== */

  .badge {
    @apply px-2 inline-flex text-xs leading-5 font-semibold rounded bg-green-100 text-green-800;
  }

  .backdrop-bg {
    /* backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 0, 0.35); */
    background-color: rgba(0, 0, 0, 0.5);
  }

  .btn {
    @apply py-2 px-8 bg-primary min-w-max mx-auto text-white text-sm font-normal w-min transition ease-in duration-200 text-center hover:shadow-blue-btn focus:outline-none rounded-full;
  }

  .btn-with-icon {
    @apply btn mx-0 py-1 px-4 flex items-center;
  }

  .btn-secondary {
    @apply btn mx-0 py-1 px-4 bg-secondary text-primary;
  }

  .card {
    /* max-h-full */
    @apply flex flex-col bg-white shadow-center-light rounded-20 overflow-visible;
  }

  .list {
    @apply divide-y divide-light;
  }

  .cardItem {
    @apply group block rounded flex items-center py-4 px-6 text-txt-light-2 hover:bg-primary hover:text-white group-hover:text-white dark:group-hover:text-white cursor-pointer;
  }
  .cardItemSelected {
    @apply group block rounded flex items-center py-4 px-6 bg-primary text-white dark:text-white cursor-pointer;
  }

  .corner {
    position: absolute;
    width: 18px;
    height: 18px;
    left: -18px;
    bottom: 0;
    overflow: hidden;
    background: transparent;
  }

  .corner::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 0;
    border-bottom-right-radius: 15px;
    box-shadow: 10px 10px 0 10px rgb(107 114 128 / 25%);
  }

  /* ======================================== Headings ======================================== */
  .heading-h2 {
    @apply text-2xl font-light text-center text-txt;
  }
  .heading-h3 {
    @apply text-sm text-gray-500 text-center;
  }

  .subheading {
    @apply text-xs text-txt text-center;
  }

  /* ======================================== Icon ======================================== */
  .icon {
    @apply transform transition hover:scale-110;
  }

  /* ======================================== Inputs ======================================== */
  .input {
    @apply rounded-md flex-1 w-full py-3 px-4 shadow-lg bg-white text-txt text-xs placeholder-txt-light text-center border-none focus:outline-none focus:ring-1 focus:ring-primary;
  }

  .input-search {
    @apply relative flex items-center w-full text-txt-light focus-within:text-black dark:focus-within:text-white;
  }

  /* ======================================== Link ======================================== */
  .link {
    @apply text-primary cursor-pointer underline;
  }

  /* ======================================== Loader ======================================== */
  .loader-wrapper {
    @apply w-full h-full flex flex-1 items-center justify-center;
  }

  /* ======================================== Login card ======================================== */
  .login-card {
    @apply max-w-sm mx-auto p-10 bg-white rounded shadow-xl content-center space-y-8;
  }

  /* ======================================== Side menu ======================================== */
  .sidememenu-nav {
    @apply mt-5 flex-1 flex flex-col overflow-y-auto;
  }

  .sidemenu-item {
    @apply relative max-w-full flex items-center px-6 py-3 text-sm leading-6 font-medium hover:bg-primary;
  }

  .sidemenu-item-sub-1 {
    @apply sidemenu-item pl-20 hover:bg-primary;
  }

  .sidemenu-item-sub-2 {
    @apply sidemenu-item-sub-1 bg-black pl-24;
  }

  .sidemenu-sub-divider-1 {
    @apply block group-hover:bg-white bg-sidemenu-sublines w-0.5 h-full;
  }

  .sidemenu-sub-divider-2 {
    @apply sidemenu-sub-divider-1 w-4 h-0.5;
  }

  /* ======================================== Tabs ======================================== */

  .tab {
    @apply w-full font-medium text-center border-transparent text-gray-500 whitespace-nowrap border-b-2 py-4 px-1
            hover:text-gray-700 hover:border-gray-200;
  }
  .tabActive {
    @apply w-full font-bold text-center border-primary text-primary whitespace-nowrap border-b-2 py-4 px-1;
  }
  .tabs {
    @apply h-full bg-white rounded-md overflow-visible;
  }
  .tabsHeader {
    @apply flex justify-between items-center border-b border-gray-200;
  }

  /* ======================================== Table ======================================== */

  .table-t {
    @apply shadow overflow-hidden border-b border-gray-200 sm:rounded-md;
  }

  .table-card {
    @apply flex flex-col items-start justify-center content-start min-w-56 rounded-md shadow-2xl absolute top-6 left-7 z-40 overflow-hidden;
  }

  .table-card-header {
    @apply flex items-center text-xs text-txt-light p-3 pb-1;
  }

  .table-card-body {
    @apply flex flex-col divide-y divide-gray-500 w-full;
  }

  /* #NOTE: not used directly in JSX, used instead in TableDefault.modules.scss via ReactVirtualized__Table__headerRow class as an override */
  .table-header {
    @apply flex items-center bg-grey-light;
  }

  .table-header-cell {
    @apply px-3 py-3 w-full flex items-center text-left font-bold text-txt-dark tracking-wider;
  }

  /* #NOTE: not used directly in JSX, used instead in TableDefault.modules.scss via ReactVirtualized__Grid__innerScrollContainer class as an override */
  .table-body {
    /* @apply divide-y divide-grey; */
  }

  .table-rw {
    @apply relative flex w-full items-center bg-white border-x border-b border-grey-light 
        hover:bg-light;
  }

  .table-row-cell {
    @apply px-3 py-3 w-full whitespace-nowrap text-left;
  }

  /* ======================================== Progressbar ======================================== */

  .progressbar {
    @apply w-full flex flex-1 items-center justify-end font-bold relative rounded-xl overflow-hidden text-right border-black;

    min-height: 24px;
    border-width: 1px;
  }

  .progressbar-fill {
    @apply w-full h-full absolute rounded-xl left-0;
  }
}

@layer utilities {
  .placeholder-italic::placeholder {
    @apply italic;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: #8a50a0;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  font-size: 12px;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.player {
  max-height: calc(100vh - 240px);
}
