.wTable {
  display: flex;
  flex: 1;
  max-height: calc(100vh - 170px);

  // Allows horizontal scroll - i dont know how, don't ask me
  & > div {
    height: 100% !important;
  }
}

.tableCell > * {
  @apply truncate;
}

.column {
  overflow: visible !important;
}

// The outside Grid
.ReactVirtualized__Table__Grid {
  background-color: white;
  outline: none;
}

// The inner, scrollable Grid (a.k.a table body)
.ReactVirtualized__Grid__innerScrollContainer {
  //   @apply divide-y divide-grey; // Tailwind CSS class: .table-body
  overflow: visible !important;
}

// Header row
.ReactVirtualized__Table__headerRow {
  @apply flex items-center bg-gray-50; // Tailwind CSS class: .table-header
}

.ReactVirtualized__Table__headerColumn {
  // @apply px-6 py-3 w-full text-left text-xs font-medium text-txt-dark uppercase tracking-wider dark:text-gray-200;
}

// Table row
.ReactVirtualized__Table__row {
  @apply bg-white relative flex w-full items-center cursor-pointer hover:bg-light;
  overflow: visible !important;
}

// Table column
.ReactVirtualized__Table__rowColumn {
  margin: 0 15px !important;
  display: flex;
}
